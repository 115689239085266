import {observer} from "mobx-react-lite";
import {useQuery} from "@apollo/client";
import React from "react";

import {Navigate} from "react-router-dom";
import {TypePicker} from "./TypePicker";
import {GET_USER_REGISTRATION} from "./Queries";
import {useStore} from "./providers/StoreContext";


export  const  NavigateToRegistrationTypeComponent = observer(() => {
    const store = useStore();
    let retControl = <>Loading..</>;
    let val =""
        const {
            loading,
            error,
            data
        } = useQuery(GET_USER_REGISTRATION,{variables:{userId:store.indexStore.userId}});

        if (!loading && data?.registrationByUserId) {

            if (data?.registrationByUserId.length > 0) {
                switch (data?.registrationByUserId[0].status ){
                    case "Approved":
                        val = 'acknowledgement';
                        break;
                    case "Acknowledged":
                        val = 'portal';
                        break;
                    default:
                        val = data?.registrationByUserId[0].ptype;
                }
                if (val === 'undefined'){
                    val = "/";
                }
                if (store.registrationStore.registrationData) {
                    store.registrationStore.registrationData.setpType(val);
                    store.registrationStore.registrationData.setStatus(data?.registrationByUserId[0].status);
                    store.registrationStore.registrationData.setId(data?.registrationByUserId[0].id);
                    store.indexStore.setReason(data?.registrationByUserId[0].reason);
                }

            }

            if (store?.registrationStore?.registrationData?.ptype) {
                val = `${store.registrationStore.registrationData.ptype}`
            }

            retControl = <p>Access Token has been expired for security reason please logout and login again</p>;
            if (error && process.env.REACT_APP_ENV === "staging") {
                retControl = <p>Error : {error?.message}</p>;
            }

            retControl = <TypePicker></TypePicker>;
            if (val) {
                retControl = <Navigate to={val} replace={true}/>;
            }
        }

    return (retControl);

});