
import {
    Accordion, AccordionDetails, AccordionSummary,
    Box, Button, Dialog, DialogActions, DialogContent, DialogTitle,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select, Snackbar,
    Stack,
    TextField, Typography
} from "@mui/material";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import {DatePicker} from "@mui/x-date-pickers";
import React, {useEffect} from "react";
import {Controller, useForm} from "react-hook-form";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useApolloClient, useMutation, useQuery} from '@apollo/client';
import dayjs from "dayjs";
import { getUrl } from "aws-amplify/storage";
import {observer} from "mobx-react-lite";
import {NavigateFunction, useNavigate, useParams} from "react-router-dom";
import {
    GET_REGISTRATIONS,
    GET_USER_FULL_REGISTRATION,
    SIGNURL,
    UPDATE_REGISTRATION_STATUS
} from "./Queries";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import {useStore} from "./providers/StoreContext";

export const AdminRegisterForm =  observer(()=> {
    const store = useStore();
    const client = useApolloClient();
    const navigate:NavigateFunction = useNavigate();
    const {userId} =useParams();

    const {loading, error, data} = useQuery(GET_USER_FULL_REGISTRATION, {variables:{userId:userId}});

    const {formState: {errors}, control, reset} = useForm( );
    const [updateStatus] = useMutation(UPDATE_REGISTRATION_STATUS,{awaitRefetchQueries:true, refetchQueries: [GET_REGISTRATIONS, 'getRegistrations']});
    const UpdateApprove=(id : string)=>{
        store.approval.openDialog=false;
        updateStatus({variables: {"id": id, "status": "Approved", "reason":null}}).then();
        navigate("/administration");
    }
    const UpdateRejection=(id:string)=>{
        store.rejectionReasonStore.openRejectionDialog=false;
        updateStatus({variables: {"id": id, "status": "Rejected", "reason":store.rejectionReasonStore.rejectionReason}}).then();
        navigate("/administration");
    }
        useEffect(()=>{
        if ((data?.registrationByUserId.length) > 0 && !store.registrationStore.urlComputed &&!loading) {

            store.registrationStore.setIpaName("Loading...");

            client.query({
                query:SIGNURL,
                variables: {
                    input: {
                        bucket: process.env.REACT_APP_BUCKET_NAME,
                        key: `private/${data?.registrationByUserId[0].bucket}/${data?.registrationByUserId[0].ipa_Certificate}`
                    }
                }
            }).then((d)=>{
                store.registrationStore.setIpaName(data?.registrationByUserId[0].ipa_Certificate);
                store.registrationStore.setIpaDownloadUrl(d?.data.signurl.url);
            });

            store.registrationStore.setTinName("Loading...");

            client.query({
                query:SIGNURL,
                variables: {
                    input: {
                        bucket: process.env.REACT_APP_BUCKET_NAME,
                        key: `private/${data?.registrationByUserId[0].bucket}/${data?.registrationByUserId[0].tin_Certificate}`
                    }
                }
            }).then((d)=>{
                store.registrationStore.setTinName(data?.registrationByUserId[0].tin_Certificate);
                store.registrationStore.setTinDownloadUrl(d?.data.signurl.url);
            });


            store.registrationStore.setMedicalName("Loading...");

            client.query({
                query:SIGNURL,
                variables: {
                    input: {
                        bucket: process.env.REACT_APP_BUCKET_NAME,
                        key: `private/${data?.registrationByUserId[0].bucket}/${data?.registrationByUserId[0].medical_Certificate}`
                    }
                }
            }).then((d)=>{
                store.registrationStore.setMedicalName(data?.registrationByUserId[0].medical_Certificate);
                store.registrationStore.setMedicalDownloadUrl(d?.data.signurl.url);
            });

            getUrl({
                path:`private/${data?.registrationByUserId[0].bucket}/${data?.registrationByUserId[0].medical_Certificateed}`,
                options: {
                    validateObjectExistence: false,  // defaults to false
                    expiresIn: 3600// validity of the URL, in seconds. defaults to 900 (15 minutes) and maxes at 3600 (1 hour)
                },
            }).then((med) => store.registrationStore.setMedicalDownloadUrl(med.url.toString()));
            store.registrationStore.setUrlComputed(true);
            store.indexStore.setRegistrationId(data?.registrationByUserId[0].luhnRegistrationNumber);
            store.indexStore.setReason(data?.registrationByUserId[0].reason);
            reset(data?.registrationByUserId[0]);

        }
        });
        if (loading) return (<p> loading..</p>);
        if (process.env.REACT_APP_ENV === "staging") {
            if (error) return (<p>Error : {error.message}</p>);
        }
        if (process.env.REACT_APP_ENV === "production") {
            if (error) return (
                <p>Access Token has been expired for security reason please logout and login again</p>);
        }
            const handleChange =
                (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
                    store.registrationStore.setActivePanel(isExpanded ? panel : false);
                };
    const handleClose = () => {
        store.appStore.setOpenSnackbar(false)
    };
    return (
        <Grid container spacing={{xs: 2, md: 3}} columns={{xs: 4, sm: 8, md: 12}} justifyContent="center">
            <Grid item xs={4} sm={7} md={10}>
                <Stack spacing={1} component="form" >
                    <Accordion sx={{background:"#F7FAFD"}} expanded={store.registrationStore.expandPanel1} onChange={handleChange("panel1")}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel1-content"
                                          id="panel1-header">
                            <Typography>Public Officer & Medical Practitioner Details</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack spacing={2}>
                                <Controller name="public_officer_firstname" disabled={true} render={({field})=> <TextField error={errors.public_officer_firstname && true}  helperText={errors?.public_officer_firstname?.message?.toString()}  sx={{background:"white"}} label='Public Officer First Name' {...field}/> }
                                            control = {control}
                                            rules={{required:{value:true,message:"Requiered"}}}
                                            defaultValue=""/>

                                <Controller name="public_officer_lastname" disabled={true} render={({field})=> <TextField error={errors.public_officer_lastname && true}  helperText={errors?.public_officer_lastname?.message?.toString()}  sx={{background:"white"}} label='Public Officer Last Name' {...field}/> }
                                            control = {control}
                                            rules={{required:{value:true,message:"Requiered"}}}
                                            defaultValue=""/>

                                <Controller name="medical_Practitioner_firstname" disabled={true} render={({field})=> <TextField error={errors.medical_Practitioner_firstname && true}  helperText={errors?.medical_Practitioner_firstname?.message?.toString()}  sx={{background:"white"}} label='Medical Practitioner First Name' {...field}/> }
                                            control = {control}
                                            rules={{required:{value:true,message:"Requiered"}}}
                                            defaultValue=""/>
                                <Controller name="medical_Practitioner_lastname" disabled={true} render={({field})=> <TextField error={errors.medical_Practitioner_lastname && true}  helperText={errors?.medical_Practitioner_lastname?.message?.toString()}  sx={{background:"white"}} label='Medical Practitioner Last Name' {...field}/> }
                                            control = {control}
                                            rules={{required:{value:true,message:"Requiered"}}}
                                            defaultValue=""/>

                                <Controller name="applicantsTermsInPractice" disabled={true} render={({field})=> <TextField error={errors.applicantsTermsInPractice && true}  helperText={errors?.applicantsTermsInPractice?.message?.toString()}  sx={{background:"white"}} label='Applicant’s Terms In Practice' {...field}/> }
                                            control = {control}
                                            rules={{required:{value:true,message:"Requiered"}}}
                                            defaultValue=""/>

                                <Controller name="mb_Registration_Number" disabled={true} render={({field})=> <TextField error={errors.mb_Registration_Number && true}  helperText={errors?.mb_Registration_Number?.message?.toString()}  sx={{background:"white"}} label='Medical Board Registration Number' {...field}/> }
                                            control = {control}
                                            rules={{required:{value:true,message:"Requiered"}}}
                                            defaultValue=""/>

                                <Controller name="rn_Expiry" disabled={true} rules={{required: {value: true, message: "Requiered"}}} control={control}

                                            render={({field}) => (
                                                <DatePicker disabled={true}  sx={{width: "100%", background:"white"}} label="Registration number Expiry Date"
                                                            slotProps={{textField:{helperText:errors?.rn_Expiry?.message?.toString(), error:errors.rn_Expiry && true}}}
                                                            format="DD/MM/YYYY"
                                                            onChange={(date) => field.onChange(date)}
                                                            value={dayjs(field.value ?? undefined)}></DatePicker>
                                            )}/>
                                <Controller name="pbox_Name" disabled={true} render={({field})=> <TextField error={errors.pbox_Name && true}  helperText={errors?.pbox_Name?.message?.toString()}  sx={{background:"white"}} label='P.O Box Name' {...field}/> }
                                            control = {control}
                                            rules={{required:{value:true,message:"Requiered"}}}
                                            defaultValue=""/>

                                <Controller name="pbox_Number" disabled={true}  render={({field})=> <TextField error={errors.pbox_Number && true}  helperText={errors?.pbox_Number?.message?.toString()}  sx={{background:"white"}} label='P.O Box Number' {...field}/> }
                                            control = {control}
                                            rules={{required:{value:true,message:"Requiered"}}}
                                            defaultValue=""/>

                                <Controller name="pbox_Branch" disabled={true}  render={({field})=> <TextField error={errors.pbox_Branch && true}  helperText={errors?.pbox_Branch?.message?.toString()}  sx={{background:"white"}} label='P.O Box Branch' {...field}/> }
                                            control = {control}
                                            rules={{required:{value:true,message:"Requiered"}}}
                                            defaultValue=""/>

                                <FormControl fullWidth={true} disabled={true}  error={errors.pbox_Province && true}  >
                                    <InputLabel id="pbox_Province_label">P.O Box Province</InputLabel>
                                    <Controller name="pbox_Province" rules={{required: {value: true, message: "Requiered"}}} control={control}
                                                render={({field}) => (
                                                    <Select sx={{background:"white"}} id="pbox_Province" label="P.O Box Province"
                                                            labelId="pbox_Province_label"
                                                            onChange={(data) => field.onChange(data)}
                                                            value={field.value ?? ""}>
                                                        <MenuItem value="Bougainville">Bougainville</MenuItem>
                                                        <MenuItem value="Central">Central</MenuItem>
                                                        <MenuItem value="Chimbu(Simbu)">Chimbu(Simbu)</MenuItem>
                                                        <MenuItem value="Eastern Highlands">Eastern Highlands</MenuItem>
                                                        <MenuItem value="East New Britain">East New Britain</MenuItem>
                                                        <MenuItem value="East Sepik">East Sepik</MenuItem>
                                                        <MenuItem value="Enga">Enga</MenuItem>
                                                        <MenuItem value="Gulf">Gulf</MenuItem>
                                                        <MenuItem value="Madang">Madang</MenuItem>
                                                        <MenuItem value="Manus">Manus</MenuItem>
                                                        <MenuItem value="Milne Bay">Milne Bay</MenuItem>
                                                        <MenuItem value="Morobe">Morobe</MenuItem>
                                                        <MenuItem value="New Ireland">New Ireland</MenuItem>
                                                        <MenuItem value="Oro (Northern)">Oro (Northern)</MenuItem>
                                                        <MenuItem value="Southern Highlands">Southern Highlands</MenuItem>
                                                        <MenuItem value="Western (Fly)">Western (Fly)</MenuItem>
                                                        <MenuItem value="Western Highlands">Western Highlands</MenuItem>
                                                        <MenuItem value="West New Britain">West New Britain</MenuItem>
                                                        <MenuItem value="Sandaun (West Sepik)">Sandaun (West Sepik)</MenuItem>
                                                        <MenuItem value="National Capital District">National Capital District</MenuItem>
                                                        <MenuItem value="Hela">Hela</MenuItem>
                                                        <MenuItem value="Jiwaka">Jiwaka</MenuItem>
                                                    </Select>

                                                )}></Controller>
                                    <FormHelperText>{errors?.pbox_Province?.message?.toString()}</FormHelperText>
                                </FormControl>


                                <Controller name="mobile_Phone_Number" disabled={true}  render={({field})=> <TextField error={errors.mobile_Phone_Number && true}  helperText={errors?.mobile_Phone_Number?.message?.toString()}  sx={{background:"white"}} label='Public Officer Mobile Phone Number' {...field}/> }
                                            control = {control}
                                            rules={{required:{value:true,message:"Requiered"}}}
                                            defaultValue=""/>


                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{background:"#F7FAFD"}} expanded={store.registrationStore.expandPanel2} onChange={handleChange("panel2")}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel2-content"
                                          id="panel2-header">
                            <Typography> Business Details</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack spacing={2}>


                                <Controller name="practice_Name" disabled={true} render={({field})=> <TextField error={errors.practice_Name && true}  helperText={errors?.practice_Name?.message?.toString()}  sx={{background:"white"}} label='Service Provider or Medical Center Name' {...field}/> }
                                            control = {control}
                                            rules={{required:{value:true,message:"Requiered"}}}
                                            defaultValue=""/>

                                <Controller name="email" disabled={true}  render={({field})=> <TextField error={errors.email && true}  helperText={errors?.email?.message?.toString()}  sx={{background:"white"}} label='Business Email' {...field}/> }
                                            control = {control}
                                            rules={{required:{value:true,message:"Requiered"}}}
                                            defaultValue=""/>

                                <Controller name="business_Phone_Number" disabled={true}  render={({field})=> <TextField error={errors.business_Phone_Number && true}  helperText={errors?.business_Phone_Number?.message?.toString()}  sx={{background:"white"}} label='Business Phone Number' {...field}/> }
                                            control = {control}
                                            rules={{required:{value:true,message:"Requiered"}}}
                                            defaultValue=""/>

                                <Controller name="location_Creation_Date" disabled={true}  control={control} rules={{required: {value: true, message: "Requiered"}}}
                                            render={({field}) => (
                                                <DatePicker disabled={true}  sx={{width: "100%",background:"white"}} label="Business Inception Date"
                                                            slotProps={{textField:{helperText:errors?.location_Creation_Date?.message?.toString(), error:errors.location_Creation_Date && true}}}
                                                            format="DD/MM/YYYY"
                                                            onChange={(date) => field.onChange(date)}
                                                            value={dayjs(field.value)}></DatePicker>
                                            )}/>
                                <Controller name="practice_Section" disabled={true}  render={({field})=> <TextField error={errors.practice_Section && true}  helperText={errors?.practice_Section?.message?.toString()}  sx={{background:"white"}} label='Practice Section' {...field}/> }
                                            control = {control}
                                            rules={{required:{value:true,message:"Requiered"}}}
                                            defaultValue=""/>

                                <Controller name="practice_Lot" disabled={true}  render={({field})=> <TextField error={errors.practice_Lot && true}  helperText={errors?.practice_Lot?.message?.toString()}  sx={{background:"white"}} label='Practice Lot' {...field}/> }
                                            control = {control}
                                            rules={{required:{value:true,message:"Requiered"}}}
                                            defaultValue=""/>

                                <Controller name="practice_Street" disabled={true}  render={({field})=> <TextField error={errors.practice_Street && true}  helperText={errors?.practice_Street?.message?.toString()}  sx={{background:"white"}} label='Practice Street' {...field}/> }
                                            control = {control}
                                            rules={{required:{value:true,message:"Requiered"}}}
                                            defaultValue=""/>

                                <Controller name="practice_Suburb" disabled={true}  render={({field})=> <TextField error={errors.practice_Suburb && true}  helperText={errors?.practice_Suburb?.message?.toString()}  sx={{background:"white"}} label='Practice Suburb' {...field}/> }
                                            control = {control}
                                            rules={{required:{value:true,message:"Requiered"}}}
                                            defaultValue=""/>

                                <FormControl fullWidth={true} disabled={true}   error={errors.practice_Province && true}  >
                                    <InputLabel id="practice_Province_label">Practice Province</InputLabel>
                                    <Controller name="practice_Province" rules={{required: {value: true, message: "Requiered"}}} control={control}
                                                render={({field}) => (
                                                    <Select sx={{background:"white"}} id="practice_Province" label="Practice Province"
                                                            labelId="practice_Province_label"
                                                            onChange={(data) => field.onChange(data)}
                                                            value={field.value ?? ""}>
                                                        <MenuItem value="Bougainville">Bougainville</MenuItem>
                                                        <MenuItem value="Central">Central</MenuItem>
                                                        <MenuItem value="Chimbu(Simbu)">Chimbu(Simbu)</MenuItem>
                                                        <MenuItem value="Eastern Highlands">Eastern Highlands</MenuItem>
                                                        <MenuItem value="East New Britain">East New Britain</MenuItem>
                                                        <MenuItem value="East Sepik">East Sepik</MenuItem>
                                                        <MenuItem value="Enga">Enga</MenuItem>
                                                        <MenuItem value="Gulf">Gulf</MenuItem>
                                                        <MenuItem value="Madang">Madang</MenuItem>
                                                        <MenuItem value="Manus">Manus</MenuItem>
                                                        <MenuItem value="Milne Bay">Milne Bay</MenuItem>
                                                        <MenuItem value="Morobe">Morobe</MenuItem>
                                                        <MenuItem value="New Ireland">New Ireland</MenuItem>
                                                        <MenuItem value="Oro (Northern)">Oro (Northern)</MenuItem>
                                                        <MenuItem value="Southern Highlands">Southern Highlands</MenuItem>
                                                        <MenuItem value="Western (Fly)">Western (Fly)</MenuItem>
                                                        <MenuItem value="Western Highlands">Western Highlands</MenuItem>
                                                        <MenuItem value="West New Britain">West New Britain</MenuItem>
                                                        <MenuItem value="Sandaun (West Sepik)">Sandaun (West Sepik)</MenuItem>
                                                        <MenuItem value="National Capital District">National Capital District</MenuItem>
                                                        <MenuItem value="Hela">Hela</MenuItem>
                                                        <MenuItem value="Jiwaka">Jiwaka</MenuItem>
                                                    </Select>

                                                )}></Controller>
                                    <FormHelperText>{errors?.practice_Province?.message?.toString()}</FormHelperText>
                                </FormControl>

                                <Controller name="applicant_Employment_Status" disabled={true}  render={({field})=> <TextField error={errors.applicant_Employment_Status && true}  helperText={errors?.applicant_Employment_Status?.message?.toString()}
                                            sx={{background:"white"}}
                                            label='Applicant’s Employment Status for this location' {...field}/> }
                                            control = {control}
                                            rules={{required:{value:true,message:"Requiered"}}}
                                            defaultValue=""/>

                                <Controller name="registered_Business_Name" disabled={true}  render={({field})=> <TextField error={errors.registered_Business_Name && true}  helperText={errors?.registered_Business_Name?.message?.toString()}
                                            sx={{background:"white"}}
                                            label='Registered Business Name' {...field}/> }
                                            control = {control}
                                            rules={{required:{value:true,message:"Requiered"}}}
                                            defaultValue=""/>

                                <Controller name="ipa_Certified_Number" disabled={true}  render={({field})=> <TextField error={errors.ipa_Certified_Number && true}  helperText={errors?.ipa_Certified_Number?.message?.toString()}
                                            sx={{background:"white"}}
                                            label='TIN Number' {...field}/> }
                                            control = {control}
                                            rules={{required:{value:true,message:"Requiered"}}}
                                            defaultValue=""/>

                                <Controller name="ipa_Registration_Number" disabled={true}  render={({field})=> <TextField error={errors.ipa_Registration_Number && true}  helperText={errors?.ipa_Registration_Number?.message?.toString()}
                                            sx={{background:"white"}}
                                            label='IPA Registration Number' {...field}/> }
                                            control = {control}
                                            rules={{required:{value:true,message:"Requiered"}}}
                                            defaultValue=""/>

                                <FormControl fullWidth={true} disabled={true}   error={errors.business_Type && true}  >
                                    <InputLabel id="Business_Type_label">Business Type</InputLabel>
                                    <Controller name="business_Type" disabled={true}  rules={{required: {value: true, message: "Requiered"}}} control={control}
                                                render={({field}) => (
                                                    <Select sx={{background:"white"}} id="business_Type" label="Business Type"

                                                            labelId="Business_Type_label"
                                                            onChange={(data) => field.onChange(data)}
                                                            value={field.value ?? ""}>
                                                        <MenuItem value="Individual proprietor">Individual
                                                            proprietor</MenuItem>
                                                        <MenuItem value="Partnership">Partnership</MenuItem>
                                                        <MenuItem value="Company">Company</MenuItem>
                                                        <MenuItem value="National Government">National
                                                            Government</MenuItem>
                                                        <MenuItem value="Provincial Government">Provincial
                                                            Government</MenuItem>
                                                        <MenuItem value="District Local Level Government">District Local
                                                            Level Government</MenuItem>
                                                    </Select>

                                                )}></Controller>
                                    <FormHelperText>{errors?.business_Type?.message?.toString()}</FormHelperText>
                                </FormControl>
                                <FormControl fullWidth={true}  disabled={true}  error={errors.premises && true}>
                                    <InputLabel id="Premises_label">Type of Practice</InputLabel>
                                    <Controller name="premises"  disabled={true} control={control} rules={{required: {value: true, message: "Requiered"}}}
                                                render={({field}) => (
                                                    <Select sx={{background:"white"}} id="premises" label="Premises_label"
                                                            labelId="Premises_label"
                                                            onChange={(data) => field.onChange(data)}
                                                            value={field.value ?? ""}>
                                                        <MenuItem value="Hospital - public">Hospital - public</MenuItem>
                                                        <MenuItem value="Hospital - private">Hospital -
                                                            private</MenuItem>
                                                        <MenuItem value="Practice - general practice">Practice - general
                                                            practice</MenuItem>
                                                        <MenuItem value="Practice - other private practice">Practice -
                                                            other private practice</MenuItem>
                                                        <MenuItem value="Educational institution">Educational
                                                            institution</MenuItem>
                                                        <MenuItem value="Residential care facility">Residential care
                                                            facility</MenuItem>
                                                        <MenuItem value="Other community health care service">Other
                                                            community health care service</MenuItem>
                                                        <MenuItem value="Mobile">Mobile</MenuItem>
                                                    </Select>
                                                )}></Controller>
                                    <FormHelperText>{errors?.premises?.message?.toString()}</FormHelperText>
                                </FormControl>
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{background:"#F7FAFD"}} expanded={store.registrationStore.expandPanel3} onChange={handleChange("panel3")}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel2-content"
                                          id="panel2-header">
                            <Typography> Business Bank Details for Remittance</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack spacing={2}>
                                <FormControl fullWidth={true} disabled={true}   error={errors.bank && true}>
                                    <InputLabel id="bank_label">Bank Name</InputLabel>
                                    <Controller name="bank" disabled={true}  control={control} rules={{required: {value: true, message: "Requiered"}}}
                                                render={({field}) => (
                                                    <Select sx={{background:"white"}} id="bank" label="bank_label" labelId="bank_label"
                                                            onChange={(data) => field.onChange(data)}
                                                            value={field.value ?? ""}>
                                                        <MenuItem value="BSP">BSP</MenuItem>
                                                        <MenuItem value="KINA BANK">KINA BANK</MenuItem>
                                                        <MenuItem value="WESTPACC">WESTPAC</MenuItem>
                                                        <MenuItem value="ANZ">ANZ</MenuItem>
                                                    </Select>
                                                )}></Controller>
                                    <FormHelperText>{errors?.bank?.message?.toString()}</FormHelperText>
                                </FormControl>

                                <Controller name="branch_Number"  disabled={true} render={({field})=> <TextField error={errors.branch_Number && true}  helperText={errors?.branch_Number?.message?.toString()}
                                            sx={{background:"white"}}
                                            label='Bank Branch Code' {...field}/> }
                                            control = {control}
                                            rules={{required:{value:true,message:"Requiered"}}}
                                            defaultValue=""/>

                                <Controller name="branch_Name" disabled={true}  render={({field})=> <TextField error={errors.branch_Name && true}  helperText={errors?.branch_Name?.message?.toString()}
                                            sx={{background:"white"}}
                                            label='Bank Branch Name' {...field}/> }
                                            control = {control}
                                            rules={{required:{value:true,message:"Requiered"}}}
                                            defaultValue=""/>

                                <Controller name="account_Number" disabled={true}  render={({field})=> <TextField error={errors.account_Number && true}  helperText={errors?.account_Number?.message?.toString()}
                                            sx={{background:"white"}}
                                            label='Account Number' {...field}/> }
                                            control = {control}
                                            rules={{required:{value:true,message:"Requiered"}}}
                                            defaultValue=""/>

                                <Controller name="account_Name" disabled={true}  render={({field})=> <TextField error={errors.account_Name && true}  helperText={errors?.account_Name?.message?.toString()}
                                            sx={{background:"white"}}
                                            label='Account Name' {...field}/> }
                                            control = {control}
                                            rules={{required:{value:true,message:"Requiered"}}}
                                            defaultValue=""/>

                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{background:"#F7FAFD"}} expanded={store.registrationStore.expandPanel4} onChange={handleChange("panel4")}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel2-content"
                                          id="panel2-header">
                            <Typography> Document Attachments</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack direction="row"  spacing={2} >
                                <Button

                                    disabled= {store.registrationStore.ipaName ===""}
                                    sx={{textTransform:'none'}}
                                    fullWidth={true}
                                    component="a"
                                    href={store?.registrationStore?.ipaDownloadUrl ?? ""}
                                    role={undefined}
                                    variant="contained"
                                    startIcon={<CloudDownloadIcon />}
                                >
                                    <Stack>
                                       <Typography>Download IPA Certificate</Typography>
                                        <Typography sx={{fontSize:"12px"}}>{store.registrationStore.ipaName}</Typography>
                                    </Stack>
                                </Button>
                                        <Button
                                            disabled={store.registrationStore.tinName ===""}
                                            sx={{textTransform:'none'}}
                                            fullWidth={true}
                                            component="a"
                                            href={store?.registrationStore?.tinDownloadUrl ?? "#"}
                                            role={undefined}
                                            variant="contained"
                                            startIcon={<CloudDownloadIcon />}
                                        >
                                            <Stack>
                                                <Typography>Download Tin Certificate</Typography>
                                                <Typography  sx={{fontSize:"12px"}}>  {store.registrationStore.tinName}</Typography>
                                            </Stack>
                                        </Button>

                                        <Button disabled={store.registrationStore.medicalName===""}
                                                sx={{textTransform:'none'}}
                                            aria-hidden={true}
                                            fullWidth={true}
                                                component="a"
                                                href={store?.registrationStore?.medicalDownloadUrl ?? "#"}
                                            role={undefined}
                                            variant="contained"
                                            startIcon={<CloudDownloadIcon />}
                                        >
                                            <Stack>
                                                <Typography>Download Medical Certificate</Typography>
                                                <Typography sx={{fontSize:"12px"}}>{store.registrationStore.medicalName}</Typography>

                                            </Stack>
                                        </Button>



                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                    <Grid container spacing={{xs: 2, md: 3}} columns={{xs: 4, sm: 8, md: 12}} justifyContent="center">
                        <Grid item>
                            <Stack direction="column"
                                   spacing={2}
                                   justifyContent="space-evenly"
                                   alignItems="center">

                                <Box sx={{border:1}} component="div" hidden={store.registrationStore.uploadingSignatureFile}>
                                    <Controller name="medical_Practitioner_Signiture" control={control}
                                                render={({field}) => (
                                                    <img alt="signiture"  src={field.value}/>)}/>
                                </Box>
                            </Stack>

                        </Grid>
                    </Grid>
                    <Grid container spacing={{xs: 2, md: 3}} columns={{xs: 4, sm: 8, md: 12}} justifyContent="center">
                        <Stack direction="row" >
                            <Button
                                    sx={{textTransform:'none'}}
                                    aria-hidden={true}
                                    fullWidth={true}
                                    component="a"
                                    onClick={()=>{store.approval.openDialog=true}}
                                    startIcon={<CheckCircleIcon color="primary"/>}>
                                <Typography>Approve</Typography>
                            </Button>
                            <Button
                                sx={{textTransform:'none'}}
                                aria-hidden={true}
                                fullWidth={true}
                                component="a"
                                onClick={()=>{store.rejectionReasonStore.openRejectionDialog=true}}
                                startIcon={<CancelIcon color="secondary"/>}>
                                <Typography>Reject</Typography>
                            </Button>
                        </Stack>
                    </Grid>
                    <Snackbar
                        anchorOrigin={{vertical:"top",horizontal: "center"}}
                        open={store.appStore.openSnackBar}
                        autoHideDuration={1000}
                        onClose = {handleClose}
                        message ={store.appStore.snackBarMessage}
                    />
                </Stack>
            </Grid>
            <Dialog open = {store.rejectionReasonStore.openRejectionDialog}  >
                <DialogTitle>Reason for Rejection</DialogTitle>
                <DialogContent>
                    <textarea rows={10} cols={50} onChange={event => store.rejectionReasonStore.rejectionReason = event.target.value}></textarea>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>store.rejectionReasonStore.openRejectionDialog=false}>Cancel</Button>
                    <Button onClick={()=>UpdateRejection(store?.registrationStore?.registrationData?.id ?? "")}>Update</Button>
                </DialogActions>
            </Dialog>
            <Dialog open = {store.approval.openDialog}  >
                <DialogTitle>Approval</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to approve this applicant?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>store.approval.openDialog=false}>No</Button>
                    <Button onClick={()=>UpdateApprove(store?.registrationStore?.registrationData?.id ?? "")}>Yes</Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
});

