
import {makeAutoObservable} from "mobx";

export class PsnaToolBar{
    constructor() {
        makeAutoObservable(this);
    }


    userFirstName :string | null = null;
    setUserFirstName (firstName:string | null ){
        this.userFirstName = firstName;
    }

    userLastName :string | null = null;
    setUserLastName (lastName:string | null ){
        this.userLastName = lastName;
    }
    userEmail :string | null = null;
    setUserEmail (email:string | null ){
        this.userEmail = email;
    }
}