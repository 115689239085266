import {observer} from "mobx-react-lite";
import {useQuery} from "@apollo/client";
import React from "react";

import {Navigate, useParams} from "react-router-dom";
import {GET_USER_REGISTRATION} from "./Queries";
import {RegistrationTable} from "./RegistrationTable";
import {RegistrationFilter} from "./RegistrationFilter";
import {useStore} from "./providers/StoreContext";


export  const  NavigateToAdminRegistrationTypeComponent = observer(() => {
    const store = useStore();
    let retControl = <>Loading..</>;
    let {userId} = useParams();
    if (!userId){
        return <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly'}}>
            <RegistrationFilter/>
            <RegistrationTable></RegistrationTable>
        </div>
    }
    let val =""
    const {
        loading,
        error,
        data
    } = useQuery(GET_USER_REGISTRATION,{variables:{userId:userId}});

    if (!loading && data?.registrationByUserId) {

        if (data?.registrationByUserId?.length > 0) {
            val = data?.registrationByUserId[0].ptype;
            if (store.registrationStore.registrationData) {
                store.registrationStore.registrationData.setpType(val);
                store.registrationStore.registrationData.setStatus(data?.registrationByUserId[0].status);
                store.registrationStore.registrationData.setId(data?.registrationByUserId[0].id);
                store.indexStore.setReason(data?.registrationByUserId[0].reason);
            }
        }

        if (store?.registrationStore?.registrationData?.ptype) {
            val = `${store.registrationStore.registrationData.ptype}`
        }

        if (error && process.env.REACT_APP_ENV === "staging") {
            retControl = <p>Error : {error.message}</p>;
        }
        if (val) {
            retControl = <Navigate to={val}  replace={true}/>;
        }
    }
    return (retControl);
});