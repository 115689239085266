import {Button, Stack, Toolbar, Typography, useMediaQuery, useTheme} from "@mui/material";
import logo from "./images/logo512.png";
import React, {useEffect} from "react";
import { signOut} from "aws-amplify/auth";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import {useStore} from "./providers/StoreContext";


export const PsnaToolBar = observer(() => {
    const store = useStore();
    const theme = useTheme();
    const bigEnough =useMediaQuery( theme.breakpoints.up('md'));
    const navigate = useNavigate();
    async function logout(){
        await signOut();
        if (store.registrationStore.registrationData) {
            store.registrationStore.registrationData.setpType("");
        }
        store.reset();
        store.appStore.setToken("");
        store.indexStore.setSignedout(true);
        store.registrationStore.setUrlComputed(false);
        store.registrationStore.setCallGetData(true);
        store.indexStore.setUserId("");

    }

    useEffect(() => {
        if (!store.appStore.token){
            navigate("/");
        }
    }, [store.appStore.token, navigate]);
    return(
        <Toolbar >
            <img width='60px' height='60px' src={logo} alt="Logo"/>
            <Typography component="div" variant="h4"    sx={{flexGrow: 1}}  display={bigEnough ? "block" : "none"}> {`Registration Form (${store.psnaToolBarStore.userFirstName}, ${store.psnaToolBarStore.userLastName})`}</Typography>
            <Typography component="div" variant="h5"    sx={{flexGrow: 1}}  display={bigEnough ? "none" : "block"}> {`${store.psnaToolBarStore.userFirstName}, ${store.psnaToolBarStore.userLastName}`}</Typography>
            <Typography sx={{flexGrow: 3}} display={bigEnough && store.indexStore.registrationId ? "block" : "none"} component="div" variant="h6">Registration Number: {store.indexStore.registrationId}</Typography>
            <Typography sx={{flexGrow: 3}} display={bigEnough && store.indexStore.registrationId ? "none" : "block"} component="div" variant="h6">{store.indexStore.registrationId}</Typography>
            <Button color="inherit" onClick={logout}>Logout</Button>

        </Toolbar>
    )
});