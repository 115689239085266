import {observer} from "mobx-react-lite";
import {AppBar, Typography} from "@mui/material";
import {PsnaToolBar} from "./PsnaToolBar";
import {Outlet} from "react-router-dom";
import React from "react";
import {useStore} from "./providers/StoreContext";

export const Registration = observer(() => {
    const store = useStore();
    return (
        <>
           <AppBar position='sticky'>
           <PsnaToolBar></PsnaToolBar>
               <Typography sx={{textAlign:'center'}} color='Red' component="div" variant="h6">{store.indexStore.reason}</Typography>
           </AppBar>
           <Outlet></Outlet>
        </>
    );
});