
import {
    Accordion, AccordionDetails, AccordionSummary,
    Box, Button, Dialog, DialogActions, DialogContent, DialogTitle,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select, Snackbar,
    Stack,
    TextField, Typography
} from "@mui/material";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import {DatePicker} from "@mui/x-date-pickers";
import React from "react";
import {Controller, useForm} from "react-hook-form";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useApolloClient, useMutation, useQuery} from '@apollo/client';
import dayjs from "dayjs"
import {observer} from "mobx-react-lite";
import {Navigate, NavigateFunction, useLocation, useNavigate, useParams} from "react-router-dom";
import {GET_REGISTRATIONS, GET_USER_FULL_REGISTRATION, SIGNURL, UPDATE_REGISTRATION_STATUS} from "./Queries";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import {useStore} from "./providers/StoreContext";

export const AdminPublicRegisterForm =  observer(()=> {
    const store = useStore();
    const client = useApolloClient();
    let navigate_to_root = false;
    let {state} = useLocation();
    const navigate:NavigateFunction = useNavigate();
    const {userId} =useParams();
    if (store.registrationStore.registrationData) {
        if (state) {
            if (store.registrationStore.registrationData.setpType !== state.val) {
                store.registrationStore.registrationData.setpType(state.val);
            }
        }
        if (store.registrationStore.registrationData.ptype !== "public") {
            navigate_to_root = true;
        }
    }
    const [updateStatus] = useMutation(UPDATE_REGISTRATION_STATUS,{awaitRefetchQueries:true, refetchQueries: [GET_REGISTRATIONS, 'getRegistrations']});

    const UpdateApprove=(id : string)=>{
        store.approval.openDialog=false;
        updateStatus({variables: {
                "id": id,
                "status": "Approved",
                "reason":null
                }}).then();

        navigate("/administration");
    }
    const UpdateRejection=(id:string)=>{
        store.rejectionReasonStore.openRejectionDialog=false;
        updateStatus({variables: {
                "id": id,
                "status": "Rejected",
                "reason":store.rejectionReasonStore.rejectionReason
        }}).then();
        navigate("/administration");
    }
    const {loading, error, data} = useQuery(GET_USER_FULL_REGISTRATION, {variables:{userId:userId}});

    const {formState: {errors}, control, reset} = useForm( );
    if (store.registrationStore.error) {
        (document.querySelector(
            `input[name="${store.registrationStore.error}"]`
        ) as HTMLInputElement | null)?.focus();
        store.registrationStore.error = null;
    }
    if ((data?.registrationByUserId.length) > 0 && !store.registrationStore.urlComputed &&  !loading) {
        store.registrationStore.setTinName("Loading...");

        client.query({
            query:SIGNURL,
            variables: {
                input: {
                    bucket: process.env.REACT_APP_BUCKET_NAME,
                    key: `private/${data?.registrationByUserId[0].bucket}/${data?.registrationByUserId[0].tin_Certificate}`
                }
            }
        }).then((d)=>{
            store.registrationStore.setTinName(data?.registrationByUserId[0].tin_Certificate);
            store.registrationStore.setTinDownloadUrl(d?.data.signurl.url);
        });

        store.registrationStore.setUrlComputed(true);
        store.indexStore.setRegistrationId(data?.registrationByUserId[0].luhnRegistrationNumber);
        store.indexStore.setReason(data?.registrationByUserId[0].reason);
        store.registrationStore.setRegistrationData(data?.registrationByUserId[0]);
        reset(data?.registrationByUserId[0]);
    }


    const handleClose = () => {
        store.appStore.setOpenSnackbar(false)
    };
    if (navigate_to_root){
        return (<Navigate to="/" />);
    }
    if (loading) return (<p> loading..</p>);
    if (process.env.REACT_APP_ENV === "staging") {
        if (error) return (<p>Error : {error.message}</p>);
    }
    if (process.env.REACT_APP_ENV === "production") {
        if (error) return (
            <p>Access Token has been expired for security reason please logout and login again</p>);
    }

        const handleChange =
            (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
                store.registrationStore.setActivePanel(isExpanded ? panel : false);
            };

    return (
        <Grid container spacing={{xs: 2, md: 3}} columns={{xs: 4, sm: 8, md: 12}} justifyContent="center">
            <Grid item xs={4} sm={7} md={10}>
                <Stack spacing={1} component="form">
                    <Accordion sx={{background:"#F7FAFD"}} expanded={store.registrationStore.expandPanel1} onChange={handleChange("panel1")}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel1-content"
                                          id="panel1-header">
                            <Typography>Public Officer Details</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack spacing={2}>

                                <Controller name="public_officer_firstname"  disabled={true} render={({field})=> <TextField error={errors.public_officer_firstname && true}  helperText={errors?.public_officer_firstname?.message?.toString()}  sx={{background:"white"}} label='Public Officer First Name' {...field}/> }
                                            control = {control}
                                            rules={{required:{value:true,message:"Requiered"}}}
                                            defaultValue=""/>

                                <Controller name="public_officer_lastname"  disabled={true} render={({field})=> <TextField error={errors.public_officer_lastname && true}  helperText={errors?.public_officer_lastname?.message?.toString()}  sx={{background:"white"}} label='Public Officer Last Name' {...field}/> }
                                            control = {control}
                                            rules={{required:{value:true,message:"Requiered"}}}
                                            defaultValue=""/>

                                <Controller name="pbox_Name" disabled={true} render={({field})=> <TextField error={errors.pbox_Name && true}  helperText={errors?.pbox_Name?.message?.toString()}  sx={{background:"white"}} label='P.O Box Name' {...field}/> }
                                            control = {control}
                                            rules={{required:{value:true,message:"Requiered"}}}
                                            defaultValue=""/>

                                <Controller name="pbox_Number" disabled={true}  render={({field})=> <TextField error={errors.pbox_Number && true}  helperText={errors?.pbox_Number?.message?.toString()}  sx={{background:"white"}} label='P.O Box Number' {...field}/> }
                                            control = {control}
                                            rules={{required:{value:true,message:"Requiered"}}}
                                            defaultValue=""/>

                                <Controller name="pbox_Branch" disabled={true}  render={({field})=> <TextField error={errors.pbox_Branch && true}  helperText={errors?.pbox_Branch?.message?.toString()}  sx={{background:"white"}} label='P.O Box Branch' {...field}/> }
                                            control = {control}
                                            rules={{required:{value:true,message:"Requiered"}}}
                                            defaultValue=""/>

                                <FormControl fullWidth={true} disabled={true}  error={errors.pbox_Province && true}  >
                                    <InputLabel id="pbox_Province_label">P.O Box Province</InputLabel>
                                    <Controller name="pbox_Province" rules={{required: {value: true, message: "Requiered"}}} control={control}
                                                render={({field}) => (
                                                    <Select sx={{background:"white"}} id="pbox_Province" label="P.O Box Province"
                                                            labelId="pbox_Province_label"
                                                            onChange={(data) => field.onChange(data)}
                                                            value={field.value ?? ""}>
                                                        <MenuItem value="Bougainville">Bougainville</MenuItem>
                                                        <MenuItem value="Central">Central</MenuItem>
                                                        <MenuItem value="Chimbu(Simbu)">Chimbu(Simbu)</MenuItem>
                                                        <MenuItem value="Eastern Highlands">Eastern Highlands</MenuItem>
                                                        <MenuItem value="East New Britain">East New Britain</MenuItem>
                                                        <MenuItem value="East Sepik">East Sepik</MenuItem>
                                                        <MenuItem value="Enga">Enga</MenuItem>
                                                        <MenuItem value="Gulf">Gulf</MenuItem>
                                                        <MenuItem value="Madang">Madang</MenuItem>
                                                        <MenuItem value="Manus">Manus</MenuItem>
                                                        <MenuItem value="Milne Bay">Milne Bay</MenuItem>
                                                        <MenuItem value="Morobe">Morobe</MenuItem>
                                                        <MenuItem value="New Ireland">New Ireland</MenuItem>
                                                        <MenuItem value="Oro (Northern)">Oro (Northern)</MenuItem>
                                                        <MenuItem value="Southern Highlands">Southern Highlands</MenuItem>
                                                        <MenuItem value="Western (Fly)">Western (Fly)</MenuItem>
                                                        <MenuItem value="Western Highlands">Western Highlands</MenuItem>
                                                        <MenuItem value="West New Britain">West New Britain</MenuItem>
                                                        <MenuItem value="Sandaun (West Sepik)">Sandaun (West Sepik)</MenuItem>
                                                        <MenuItem value="National Capital District">National Capital District</MenuItem>
                                                        <MenuItem value="Hela">Hela</MenuItem>
                                                        <MenuItem value="Jiwaka">Jiwaka</MenuItem>
                                                    </Select>

                                                )}></Controller>
                                    <FormHelperText>{errors?.pbox_Province?.message?.toString()}</FormHelperText>
                                </FormControl>

                                <Controller name="mobile_Phone_Number"  disabled={true} render={({field})=> <TextField error={errors.mobile_Phone_Number && true}  helperText={errors?.mobile_Phone_Number?.message?.toString()}  sx={{background:"white"}} label='Public Officer Mobile Phone Number' {...field}/> }
                                            control = {control}
                                            rules={{required:{value:true,message:"Requiered"}}}
                                            defaultValue=""/>


                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{background:"#F7FAFD"}} expanded={store.registrationStore.expandPanel2} onChange={handleChange("panel2")}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel2-content"
                                          id="panel2-header">
                            <Typography>Provincial Health Authority</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack spacing={2}>


                                <Controller name="practice_Name"  disabled={true} render={({field})=> <TextField error={errors.practice_Name && true}  helperText={errors?.practice_Name?.message?.toString()}  sx={{background:"white"}} label='PHA Name' {...field}/> }
                                            control = {control}
                                            rules={{required:{value:true,message:"Requiered"}}}
                                            defaultValue=""/>

                                <Controller name="email"   disabled={true} render={({field})=> <TextField error={errors.email && true}  helperText={errors?.email?.message?.toString()}  sx={{background:"white"}} label='PHA Email' {...field}/> }
                                            control = {control}
                                            rules={{required:{value:true,message:"Requiered"}}}
                                            defaultValue=""/>

                                <Controller name="business_Phone_Number"  disabled={true} render={({field})=> <TextField error={errors.business_Phone_Number && true}  helperText={errors?.business_Phone_Number?.message?.toString()}  sx={{background:"white"}} label='PHA Phone Number' {...field}/> }
                                            control = {control}
                                            rules={{required:{value:true,message:"Requiered"}}}
                                            defaultValue=""/>

                                <Controller name="location_Creation_Date"  disabled={true} control={control} rules={{required: {value: true, message: "Requiered"}}}
                                            render={({field}) => (
                                                <DatePicker sx={{width: "100%",background:"white"}} label="PHA Inception Date"
                                                            slotProps={{textField:{helperText:errors?.location_Creation_Date?.message?.toString(), error:errors.location_Creation_Date && true}}}
                                                            format="DD/MM/YYYY"
                                                            onChange={(date) => field.onChange(date)}
                                                            value={dayjs(field.value)}></DatePicker>
                                            )}/>
                                <Controller name="practice_Section"   disabled={true} render={({field})=> <TextField error={errors.practice_Section && true}  helperText={errors?.practice_Section?.message?.toString()}  sx={{background:"white"}} label='PHA Section' {...field}/> }
                                            control = {control}
                                            rules={{required:{value:true,message:"Requiered"}}}
                                            defaultValue=""/>

                                <Controller name="practice_Lot"  disabled={true} render={({field})=> <TextField error={errors.practice_Lot && true}  helperText={errors?.practice_Lot?.message?.toString()}  sx={{background:"white"}} label='PHA Lot' {...field}/> }
                                            control = {control}
                                            rules={{required:{value:true,message:"Requiered"}}}
                                            defaultValue=""/>

                                <Controller name="practice_Street"  disabled={true} render={({field})=> <TextField error={errors.practice_Street && true}  helperText={errors?.practice_Street?.message?.toString()}  sx={{background:"white"}} label='PHA Street' {...field}/> }
                                            control = {control}
                                            rules={{required:{value:true,message:"Requiered"}}}
                                            defaultValue=""/>

                                <Controller name="practice_Suburb"  disabled={true} render={({field})=> <TextField error={errors.practice_Suburb && true}  helperText={errors?.practice_Suburb?.message?.toString()}  sx={{background:"white"}} label='PHA Suburb' {...field}/> }
                                            control = {control}
                                            rules={{required:{value:true,message:"Requiered"}}}
                                            defaultValue=""/>

                                <FormControl fullWidth={true}  disabled={true}  error={errors.practice_Province && true}  >
                                    <InputLabel id="practice_Province_label">PHA Province</InputLabel>
                                    <Controller name="practice_Province" rules={{required: {value: true, message: "Requiered"}}} control={control}
                                                render={({field}) => (
                                                    <Select sx={{background:"white"}} id="practice_Province" label="PHA Province"
                                                            labelId="practice_Province_label"
                                                            onChange={(data) => field.onChange(data)}
                                                            value={field.value ?? ""}>
                                                        <MenuItem value="Bougainville">Bougainville</MenuItem>
                                                        <MenuItem value="Central">Central</MenuItem>
                                                        <MenuItem value="Chimbu(Simbu)">Chimbu(Simbu)</MenuItem>
                                                        <MenuItem value="Eastern Highlands">Eastern Highlands</MenuItem>
                                                        <MenuItem value="East New Britain">East New Britain</MenuItem>
                                                        <MenuItem value="East Sepik">East Sepik</MenuItem>
                                                        <MenuItem value="Enga">Enga</MenuItem>
                                                        <MenuItem value="Gulf">Gulf</MenuItem>
                                                        <MenuItem value="Madang">Madang</MenuItem>
                                                        <MenuItem value="Manus">Manus</MenuItem>
                                                        <MenuItem value="Milne Bay">Milne Bay</MenuItem>
                                                        <MenuItem value="Morobe">Morobe</MenuItem>
                                                        <MenuItem value="New Ireland">New Ireland</MenuItem>
                                                        <MenuItem value="Oro (Northern)">Oro (Northern)</MenuItem>
                                                        <MenuItem value="Southern Highlands">Southern Highlands</MenuItem>
                                                        <MenuItem value="Western (Fly)">Western (Fly)</MenuItem>
                                                        <MenuItem value="Western Highlands">Western Highlands</MenuItem>
                                                        <MenuItem value="West New Britain">West New Britain</MenuItem>
                                                        <MenuItem value="Sandaun (West Sepik)">Sandaun (West Sepik)</MenuItem>
                                                        <MenuItem value="National Capital District">National Capital District</MenuItem>
                                                        <MenuItem value="Hela">Hela</MenuItem>
                                                        <MenuItem value="Jiwaka">Jiwaka</MenuItem>
                                                    </Select>

                                                )}></Controller>
                                    <FormHelperText>{errors?.practice_Province?.message?.toString()}</FormHelperText>
                                </FormControl>
                                <FormControl   disabled={true} fullWidth={true}  error={errors.premises && true}>
                                    <InputLabel id="Premises_label">Type of Practice</InputLabel>
                                    <Controller name="premises" control={control} rules={{required: {value: true, message: "Requiered"}}}
                                                render={({field}) => (
                                                    <Select sx={{background:"white"}} id="premises" label="Premises_label"
                                                            labelId="Premises_label"
                                                            onChange={(data) => field.onChange(data)}
                                                            value={field.value ?? ""}>
                                                        <MenuItem value="Hospital - public">Hospital - public</MenuItem>
                                                        <MenuItem value="Hospital - private">Hospital -
                                                            private</MenuItem>
                                                        <MenuItem value="Practice - general practice">Practice - general
                                                            practice</MenuItem>
                                                        <MenuItem value="Practice - other private practice">Practice -
                                                            other private practice</MenuItem>
                                                        <MenuItem value="Educational institution">Educational
                                                            institution</MenuItem>
                                                        <MenuItem value="Residential care facility">Residential care
                                                            facility</MenuItem>
                                                        <MenuItem value="Other community health care service">Other
                                                            community health care service</MenuItem>
                                                        <MenuItem value="Mobile">Mobile</MenuItem>
                                                    </Select>
                                                )}></Controller>
                                    <FormHelperText>{errors?.premises?.message?.toString()}</FormHelperText>
                                </FormControl>

                                <Controller name="ipa_Registration_Number"  disabled={true} render={({field})=> <TextField error={errors.ipa_Registration_Number && true}  helperText={errors?.ipa_Registration_Number?.message?.toString()}
                                                                                                  sx={{background:"white"}}
                                                                                                  label='PHA Agency Code' {...field}/> }
                                            control = {control}
                                            rules={{required:{value:true,message:"Requiered"}}}
                                            defaultValue=""/>

                                <Controller name="ipa_Certified_Number"  disabled={true} render={({field})=> <TextField error={errors.ipa_Certified_Number && true}  helperText={errors?.ipa_Certified_Number?.message?.toString()}
                                            sx={{background:"white"}}
                                            label='PHA TIN' {...field}/> }
                                            control = {control}
                                            defaultValue=""/>


                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{background:"#F7FAFD"}} expanded={store.registrationStore.expandPanel3} onChange={handleChange("panel3")}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel2-content"
                                          id="panel2-header">
                            <Typography> Bank Details</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack spacing={2}>
                                <FormControl fullWidth={true}  disabled={true} error={errors.bank && true}>
                                    <InputLabel id="bank_label">Bank Name</InputLabel>
                                    <Controller name="bank" control={control} rules={{required: {value: true, message: "Requiered"}}}
                                                render={({field}) => (
                                                    <Select sx={{background:"white"}} id="bank" label="bank_label" labelId="bank_label"
                                                            onChange={(data) => field.onChange(data)}
                                                            value={field.value ?? ""}>
                                                        <MenuItem value="BSP">BSP</MenuItem>
                                                        <MenuItem value="KINA BANK">KINA BANK</MenuItem>
                                                        <MenuItem value="WESTPACC">WESTPAC</MenuItem>
                                                        <MenuItem value="ANZ">ANZ</MenuItem>
                                                    </Select>
                                                )}></Controller>
                                    <FormHelperText>{errors?.bank?.message?.toString()}</FormHelperText>
                                </FormControl>

                                <Controller name="branch_Number"  disabled={true} render={({field})=> <TextField error={errors.branch_Number && true}  helperText={errors?.branch_Number?.message?.toString()}
                                            sx={{background:"white"}}
                                            label='Bank Branch Code' {...field}/> }
                                            control = {control}
                                            rules={{required:{value:true,message:"Requiered"}}}
                                            defaultValue=""/>

                                <Controller name="branch_Name"  disabled={true} render={({field})=> <TextField error={errors.branch_Name && true}  helperText={errors?.branch_Name?.message?.toString()}
                                            sx={{background:"white"}}
                                            label='Bank Branch Name' {...field}/> }
                                            control = {control}
                                            rules={{required:{value:true,message:"Requiered"}}}
                                            defaultValue=""/>

                                <Controller name="account_Number"  disabled={true} render={({field})=> <TextField error={errors.account_Number && true}  helperText={errors?.account_Number?.message?.toString()}
                                            sx={{background:"white"}}
                                            label='Account Number' {...field}/> }
                                            control = {control}
                                            rules={{required:{value:true,message:"Requiered"}}}
                                            defaultValue=""/>

                                <Controller name="account_Name"  disabled={true} render={({field})=> <TextField error={errors.account_Name && true}  helperText={errors?.account_Name?.message?.toString()}
                                            sx={{background:"white"}}
                                            label='Account Name' {...field}/> }
                                            control = {control}
                                            rules={{required:{value:true,message:"Requiered"}}}
                                            defaultValue=""/>

                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{background:"#F7FAFD"}} expanded={store.registrationStore.expandPanel4} onChange={handleChange("panel4")}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel2-content"
                                          id="panel2-header">
                            <Typography> Document Attachments</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack spacing={2} >
                                <Grid container spacing={1} columns={{xs: 4, sm: 8, md: 12}} alignItems="center">
                                    <Grid item xs={4} sm={6} md={3}>
                                        <Button
                                            disabled={store?.registrationStore.tinName === ""}
                                            sx={{textTransform:'none'}}
                                            fullWidth={true}
                                            component="a"
                                            href={store?.registrationStore?.tinDownloadUrl ?? "#"}
                                            role={undefined}
                                            variant="contained"
                                            startIcon={<CloudDownloadIcon />}
                                        >
                                            <Stack>
                                                <Typography>Download</Typography>
                                                <Typography  sx={{fontSize:"12px"}}>{store?.registrationStore.tinName} </Typography>
                                            </Stack>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                    <Grid container spacing={{xs: 2, md: 3}} columns={{xs: 4, sm: 8, md: 12}} justifyContent="center">
                        <Grid item>
                            <Stack direction="column"
                                   spacing={2}
                                   justifyContent="space-evenly"
                                   alignItems="center">

                                <Box sx={{border:1}} component="div" hidden={store.registrationStore.uploadingSignatureFile}>
                                    <Controller name="medical_Practitioner_Signiture" control={control}
                                                render={({field}) => (
                                                    <img alt="signiture"  src={field.value}/>)}/>
                                </Box>
                            </Stack>

                        </Grid>
                    </Grid>
                    <Grid container spacing={{xs: 2, md: 3}} columns={{xs: 4, sm: 8, md: 12}} justifyContent="center">
                        <Stack direction="row" >
                            <Button
                                sx={{textTransform:'none'}}
                                aria-hidden={true}
                                fullWidth={true}
                                component="a"
                                onClick={()=>{store.approval.openDialog = true}}
                                startIcon={<CheckCircleIcon color="primary"/>}>
                                <Typography>Approve</Typography>
                            </Button>
                            <Button
                                sx={{textTransform:'none'}}
                                aria-hidden={true}
                                fullWidth={true}
                                component="a"
                                onClick={()=>{store.rejectionReasonStore.openRejectionDialog=true}}
                                startIcon={<CancelIcon color="secondary"/>}>
                                <Typography>Reject</Typography>
                            </Button>
                        </Stack>
                    </Grid>
                    <Snackbar
                        anchorOrigin={{vertical:"top",horizontal: "center"}}
                        open={store.appStore.openSnackBar}
                        autoHideDuration={1000}
                        onClose = {handleClose}
                        message ={store.appStore.snackBarMessage}
                    />
                </Stack>
            </Grid>
            <Dialog open = {store.rejectionReasonStore.openRejectionDialog}  >
                <DialogTitle>Reason for Rejection</DialogTitle>
                <DialogContent>
                    <textarea rows={10} cols={50} onChange={event => store.rejectionReasonStore.rejectionReason = event.target.value}></textarea>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>store.rejectionReasonStore.openRejectionDialog=false}>Cancel</Button>
                    <Button onClick={()=>UpdateRejection(store?.registrationStore?.registrationData?.id ?? "")}>Update</Button>
                </DialogActions>
            </Dialog>
            <Dialog open = {store.approval.openDialog}  >
                <DialogTitle>Approval</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to approve this applicant?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>store.approval.openDialog=false}>No</Button>
                    <Button onClick={()=>UpdateApprove(store?.registrationStore?.registrationData?.id ?? "")}>Yes</Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
});