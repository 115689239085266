
import {
    Accordion, AccordionDetails, AccordionSummary,
    Box, Button,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select, Snackbar,
    Stack,
    TextField, Typography
} from "@mui/material";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import {uploadData} from '@aws-amplify/storage';
import {DatePicker} from "@mui/x-date-pickers";
import React from "react";
import {LoadingButton} from "@mui/lab";
import {Controller, useForm} from "react-hook-form";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {gql, useQuery, useMutation, } from '@apollo/client';
import dayjs from "dayjs";
import { ImageUpload } from "./ImageUpload";
import {Signature} from "./Signature";
import {MuiFileInput} from "mui-file-input";

import { getUrl } from "aws-amplify/storage";

import {observer} from "mobx-react-lite";

import { Navigate, useLocation} from "react-router-dom";
import {useStore} from "./providers/StoreContext";
import {GET_USER_FULL_REGISTRATION} from "./Queries";

export const PublicRegisterForm =  observer(()=> {
    const store = useStore();
    let navigatetoroot = false;
    let {state} = useLocation();
    if (store.registrationStore.registrationData) {
        if (state) {
            if (store.registrationStore.registrationData.setpType !== state.val) {
                store.registrationStore.registrationData.setpType(state.val);
            }
        }
        if (store.registrationStore.registrationData.ptype !== "public") {
            navigatetoroot = true;
        }
    }
    const ADDORUPDATE_REGISTRATION = gql(`
mutation AddOrUpdate($reg:RegistrationInput!){
  addOrUpdateRegistration(registration: $reg){
          id
          luhnRegistrationNumber
  }
}
    `);


    const [addFunc, {
        loading: mloading,
    }] = useMutation(ADDORUPDATE_REGISTRATION,{awaitRefetchQueries:true, refetchQueries: [GET_USER_FULL_REGISTRATION, 'GetUserRegistration']});

    const {loading, error, data} = useQuery(GET_USER_FULL_REGISTRATION, {variables:{userId:store.indexStore.userId}});

    const {formState: {errors}, handleSubmit, control, reset, getValues} = useForm( );
    if (store.registrationStore.error) {
        (document.querySelector(
            `input[name="${store.registrationStore.error}"]`
        ) as HTMLInputElement | null)?.focus();
        store.registrationStore.error = null;
    }
    if ((data?.registrationByUserId.length) > 0 && !store.registrationStore.urlComputed && !mloading && !loading) {

        getUrl({
            path:({identityId})=>`private/${identityId}/${data?.registrationByUserId[0].tin_Certificate}`,
            options: {
                validateObjectExistence: false,  // defaults to false
                expiresIn: 3600// validity of the URL, in seconds. defaults to 900 (15 minutes) and maxes at 3600 (1 hour)
            },
        }).then((tin) => {
            store.registrationStore.setTinDownloadUrl(tin.url.toString())
        });


        store.registrationStore.setUrlComputed(true);

        store.indexStore.setRegistrationId(data?.registrationByUserId[0].luhnRegistrationNumber);
        store.indexStore.setReason(data?.registrationByUserId[0].reason);
        store.registrationStore.setRegistrationData(data?.registrationByUserId[0]);
        reset(data?.registrationByUserId[0]);

    }
    if (navigatetoroot){
        return (<Navigate to="/" />);
    }
    if (loading) return (<p> loading..</p>);
    if (process.env.REACT_APP_ENV === "staging") {
        if (error) return (<p>Error : {error.message}</p>);
    }
    if (process.env.REACT_APP_ENV === "production") {
        if (error) return (
            <p>Access Token has been expired for security reason please logout and login again</p>);
    }
    const submitform = (formData: any) => {
        const asyncSubmitForm = async () => {

            if (formData.tin_Certificate?.name) {
                store.registrationStore.toggleTinUploading();
                await uploadData({
                    path:({identityId})=>`private/${identityId}/${formData.tin_Certificate?.name}`,
                    data: formData.tin_Certificate,
                }).result;
                store.registrationStore.toggleTinUploading();
            }

            formData.tin_Certificate = formData.tin_Certificate?.name ?? formData.tin_Certificate;
            delete formData.__typename;
            formData.userId = store.indexStore.userId;
            store.registrationStore.setRegistrationData(formData)
            if (store.registrationStore.registrationData) {
                store.registrationStore.registrationData.setBucket(store?.appStore?.identityId ?? "");
            }
            try {
                await addFunc({variables: {"reg": store.registrationStore.registrationData}},);
            } catch (e) {
                store.appStore.showSnackBar("Unable to Save");
                throw  e
            }
            store.appStore.showSnackBar("Saved");
            store.registrationStore.setCallGetData(true);
            store.registrationStore.setExpandPanels(false);
            store.registrationStore.setUrlComputed(false);
        }
        asyncSubmitForm().then();
    }

        const change = (data: any) => {
            store.registrationStore.setUploadingSignatureFile(data.target.value === "UploadFile");
        }
        const handleChange =
            (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
                store.registrationStore.setActivePanel(isExpanded ? panel : false);
            };
        const onformError = (data: any) => {
            store.registrationStore.setError(Object.keys(data)[0]);
            store.registrationStore.setExpandPanels(true);
        }
    const handleClose = () => {
        store.appStore.setOpenSnackbar(false)
    };
    return (
        <Grid container spacing={{xs: 2, md: 3}} columns={{xs: 4, sm: 8, md: 12}} justifyContent="center">
            <Grid item xs={4} sm={7} md={10}>
                <Stack spacing={1} component="form" onSubmit={handleSubmit(submitform, onformError)}>
                    <Accordion sx={{background:"#F7FAFD"}} expanded={store.registrationStore.expandPanel1} onChange={handleChange("panel1")}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel1-content"
                                          id="panel1-header">
                            <Typography>Public Officer Details</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack spacing={2}>

                                <Controller name="public_officer_firstname" render={({field})=> <TextField error={errors.public_officer_firstname && true}  helperText={errors?.public_officer_firstname?.message?.toString()}  sx={{background:"white"}} label='Public Officer First Name' {...field}/> }
                                            control = {control}
                                            rules={{required:{value:true,message:"Requiered"}}}
                                            defaultValue=""/>

                                <Controller name="public_officer_lastname" render={({field})=> <TextField error={errors.public_officer_lastname && true}  helperText={errors?.public_officer_lastname?.message?.toString()}  sx={{background:"white"}} label='Public Officer Last Name' {...field}/> }
                                            control = {control}
                                            rules={{required:{value:true,message:"Requiered"}}}
                                            defaultValue=""/>

                                <Controller name="pbox_Name" render={({field})=> <TextField error={errors.pbox_Name && true}  helperText={errors?.pbox_Name?.message?.toString()}  sx={{background:"white"}} label='P.O Box Name' {...field}/> }
                                            control = {control}
                                            rules={{required:{value:true,message:"Requiered"}}}
                                            defaultValue=""/>

                                <Controller name="pbox_Number" render={({field})=> <TextField error={errors.pbox_Number && true}  helperText={errors?.pbox_Number?.message?.toString()}  sx={{background:"white"}} label='P.O Box Number' {...field}/> }
                                            control = {control}
                                            rules={{required:{value:true,message:"Requiered"}}}
                                            defaultValue=""/>

                                <Controller name="pbox_Branch" render={({field})=> <TextField error={errors.pbox_Branch && true}  helperText={errors?.pbox_Branch?.message?.toString()}  sx={{background:"white"}} label='P.O Box Branch' {...field}/> }
                                            control = {control}
                                            rules={{required:{value:true,message:"Requiered"}}}
                                            defaultValue=""/>

                                <FormControl fullWidth={true}   error={errors.pbox_Province && true}  >
                                    <InputLabel id="pbox_Province_label">P.O Box Province</InputLabel>
                                    <Controller name="pbox_Province" rules={{required: {value: true, message: "Requiered"}}} control={control}
                                                render={({field}) => (
                                                    <Select sx={{background:"white"}} id="pbox_Province" label="P.O Box Province"
                                                            labelId="pbox_Province_label"
                                                            onChange={(data) => field.onChange(data)}
                                                            value={field.value ?? ""}>
                                                        <MenuItem value="Bougainville">Bougainville</MenuItem>
                                                        <MenuItem value="Central">Central</MenuItem>
                                                        <MenuItem value="Chimbu(Simbu)">Chimbu(Simbu)</MenuItem>
                                                        <MenuItem value="Eastern Highlands">Eastern Highlands</MenuItem>
                                                        <MenuItem value="East New Britain">East New Britain</MenuItem>
                                                        <MenuItem value="East Sepik">East Sepik</MenuItem>
                                                        <MenuItem value="Enga">Enga</MenuItem>
                                                        <MenuItem value="Gulf">Gulf</MenuItem>
                                                        <MenuItem value="Madang">Madang</MenuItem>
                                                        <MenuItem value="Manus">Manus</MenuItem>
                                                        <MenuItem value="Milne Bay">Milne Bay</MenuItem>
                                                        <MenuItem value="Morobe">Morobe</MenuItem>
                                                        <MenuItem value="New Ireland">New Ireland</MenuItem>
                                                        <MenuItem value="Oro (Northern)">Oro (Northern)</MenuItem>
                                                        <MenuItem value="Southern Highlands">Southern Highlands</MenuItem>
                                                        <MenuItem value="Western (Fly)">Western (Fly)</MenuItem>
                                                        <MenuItem value="Western Highlands">Western Highlands</MenuItem>
                                                        <MenuItem value="West New Britain">West New Britain</MenuItem>
                                                        <MenuItem value="Sandaun (West Sepik)">Sandaun (West Sepik)</MenuItem>
                                                        <MenuItem value="National Capital District">National Capital District</MenuItem>
                                                        <MenuItem value="Hela">Hela</MenuItem>
                                                        <MenuItem value="Jiwaka">Jiwaka</MenuItem>
                                                    </Select>

                                                )}></Controller>
                                    <FormHelperText>{errors?.pbox_Province?.message?.toString()}</FormHelperText>
                                </FormControl>


                                <Controller name="mobile_Phone_Number" render={({field})=> <TextField error={errors.mobile_Phone_Number && true}  helperText={errors?.mobile_Phone_Number?.message?.toString()}  sx={{background:"white"}} label='Public Officer Mobile Phone Number' {...field}/> }
                                            control = {control}
                                            rules={{required:{value:true,message:"Requiered"}}}
                                            defaultValue=""/>


                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{background:"#F7FAFD"}} expanded={store.registrationStore.expandPanel2} onChange={handleChange("panel2")}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel2-content"
                                          id="panel2-header">
                            <Typography>Provincial Health Authority</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack spacing={2}>


                                <Controller name="practice_Name" render={({field})=> <TextField error={errors.practice_Name && true}  helperText={errors?.practice_Name?.message?.toString()}  sx={{background:"white"}} label='PHA Name' {...field}/> }
                                            control = {control}
                                            rules={{required:{value:true,message:"Requiered"}}}
                                            defaultValue=""/>

                                <Controller name="email" render={({field})=> <TextField error={errors.email && true}  helperText={errors?.email?.message?.toString()}  sx={{background:"white"}} label='PHA Email' {...field}/> }
                                            control = {control}
                                            rules={{required:{value:true,message:"Requiered"}}}
                                            defaultValue=""/>

                                <Controller name="business_Phone_Number" render={({field})=> <TextField error={errors.business_Phone_Number && true}  helperText={errors?.business_Phone_Number?.message?.toString()}  sx={{background:"white"}} label='PHA Phone Number' {...field}/> }
                                            control = {control}
                                            rules={{required:{value:true,message:"Requiered"}}}
                                            defaultValue=""/>

                                <Controller name="location_Creation_Date" control={control} rules={{required: {value: true, message: "Requiered"}}}
                                            render={({field}) => (
                                                <DatePicker sx={{width: "100%",background:"white"}} label="PHA Inception Date"
                                                            slotProps={{textField:{helperText:errors?.location_Creation_Date?.message?.toString(), error:errors.location_Creation_Date && true}}}
                                                            format="DD/MM/YYYY"
                                                            onChange={(date) => field.onChange(date)}
                                                            value={dayjs(field.value)}></DatePicker>
                                            )}/>
                                <Controller name="practice_Section" render={({field})=> <TextField error={errors.practice_Section && true}  helperText={errors?.practice_Section?.message?.toString()}  sx={{background:"white"}} label='PHA Section' {...field}/> }
                                            control = {control}
                                            rules={{required:{value:true,message:"Requiered"}}}
                                            defaultValue=""/>

                                <Controller name="practice_Lot" render={({field})=> <TextField error={errors.practice_Lot && true}  helperText={errors?.practice_Lot?.message?.toString()}  sx={{background:"white"}} label='PHA Lot' {...field}/> }
                                            control = {control}
                                            rules={{required:{value:true,message:"Requiered"}}}
                                            defaultValue=""/>

                                <Controller name="practice_Street" render={({field})=> <TextField error={errors.practice_Street && true}  helperText={errors?.practice_Street?.message?.toString()}  sx={{background:"white"}} label='PHA Street' {...field}/> }
                                            control = {control}
                                            rules={{required:{value:true,message:"Requiered"}}}
                                            defaultValue=""/>

                                <Controller name="practice_Suburb" render={({field})=> <TextField error={errors.practice_Suburb && true}  helperText={errors?.practice_Suburb?.message?.toString()}  sx={{background:"white"}} label='PHA Suburb' {...field}/> }
                                            control = {control}
                                            rules={{required:{value:true,message:"Requiered"}}}
                                            defaultValue=""/>

                                <FormControl fullWidth={true}   error={errors.practice_Province && true}  >
                                    <InputLabel id="practice_Province_label">PHA Province</InputLabel>
                                    <Controller name="practice_Province" rules={{required: {value: true, message: "Requiered"}}} control={control}
                                                render={({field}) => (
                                                    <Select sx={{background:"white"}} id="practice_Province" label="PHA Province"
                                                            labelId="practice_Province_label"
                                                            onChange={(data) => field.onChange(data)}
                                                            value={field.value ?? ""}>
                                                        <MenuItem value="Bougainville">Bougainville</MenuItem>
                                                        <MenuItem value="Central">Central</MenuItem>
                                                        <MenuItem value="Chimbu(Simbu)">Chimbu(Simbu)</MenuItem>
                                                        <MenuItem value="Eastern Highlands">Eastern Highlands</MenuItem>
                                                        <MenuItem value="East New Britain">East New Britain</MenuItem>
                                                        <MenuItem value="East Sepik">East Sepik</MenuItem>
                                                        <MenuItem value="Enga">Enga</MenuItem>
                                                        <MenuItem value="Gulf">Gulf</MenuItem>
                                                        <MenuItem value="Madang">Madang</MenuItem>
                                                        <MenuItem value="Manus">Manus</MenuItem>
                                                        <MenuItem value="Milne Bay">Milne Bay</MenuItem>
                                                        <MenuItem value="Morobe">Morobe</MenuItem>
                                                        <MenuItem value="New Ireland">New Ireland</MenuItem>
                                                        <MenuItem value="Oro (Northern)">Oro (Northern)</MenuItem>
                                                        <MenuItem value="Southern Highlands">Southern Highlands</MenuItem>
                                                        <MenuItem value="Western (Fly)">Western (Fly)</MenuItem>
                                                        <MenuItem value="Western Highlands">Western Highlands</MenuItem>
                                                        <MenuItem value="West New Britain">West New Britain</MenuItem>
                                                        <MenuItem value="Sandaun (West Sepik)">Sandaun (West Sepik)</MenuItem>
                                                        <MenuItem value="National Capital District">National Capital District</MenuItem>
                                                        <MenuItem value="Hela">Hela</MenuItem>
                                                        <MenuItem value="Jiwaka">Jiwaka</MenuItem>
                                                    </Select>

                                                )}></Controller>
                                    <FormHelperText>{errors?.practice_Province?.message?.toString()}</FormHelperText>
                                </FormControl>
                                <FormControl fullWidth={true}  error={errors.premises && true}>
                                    <InputLabel id="Premises_label">Type of Practice</InputLabel>
                                    <Controller name="premises" control={control} rules={{required: {value: true, message: "Requiered"}}}
                                                render={({field}) => (
                                                    <Select sx={{background:"white"}} id="premises" label="Premises_label"
                                                            labelId="Premises_label"
                                                            onChange={(data) => field.onChange(data)}
                                                            value={field.value ?? ""}>
                                                        <MenuItem value="Hospital - public">Hospital - public</MenuItem>
                                                        <MenuItem value="Hospital - private">Hospital -
                                                            private</MenuItem>
                                                        <MenuItem value="Practice - general practice">Practice - general
                                                            practice</MenuItem>
                                                        <MenuItem value="Practice - other private practice">Practice -
                                                            other private practice</MenuItem>
                                                        <MenuItem value="Educational institution">Educational
                                                            institution</MenuItem>
                                                        <MenuItem value="Residential care facility">Residential care
                                                            facility</MenuItem>
                                                        <MenuItem value="Other community health care service">Other
                                                            community health care service</MenuItem>
                                                        <MenuItem value="Mobile">Mobile</MenuItem>
                                                    </Select>
                                                )}></Controller>
                                    <FormHelperText>{errors?.premises?.message?.toString()}</FormHelperText>
                                </FormControl>

                                <Controller name="ipa_Registration_Number" render={({field})=> <TextField error={errors.ipa_Registration_Number && true}  helperText={errors?.ipa_Registration_Number?.message?.toString()}
                                                                                                  sx={{background:"white"}}
                                                                                                  label='PHA Agency Code' {...field}/> }
                                            control = {control}
                                            rules={{required:{value:true,message:"Requiered"}}}
                                            defaultValue=""/>

                                <Controller name="ipa_Certified_Number" render={({field})=> <TextField error={errors.ipa_Certified_Number && true}  helperText={errors?.ipa_Certified_Number?.message?.toString()}
                                            sx={{background:"white"}}
                                            label='PHA TIN' {...field}/> }
                                            control = {control}
                                            defaultValue=""/>


                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{background:"#F7FAFD"}} expanded={store.registrationStore.expandPanel3} onChange={handleChange("panel3")}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel2-content"
                                          id="panel2-header">
                            <Typography> Bank Details</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack spacing={2}>
                                <FormControl fullWidth={true}  error={errors.bank && true}>
                                    <InputLabel id="bank_label">Bank Name</InputLabel>
                                    <Controller name="bank" control={control} rules={{required: {value: true, message: "Requiered"}}}
                                                render={({field}) => (
                                                    <Select sx={{background:"white"}} id="bank" label="bank_label" labelId="bank_label"
                                                            onChange={(data) => field.onChange(data)}
                                                            value={field.value ?? ""}>
                                                        <MenuItem value="BSP">BSP</MenuItem>
                                                        <MenuItem value="KINA BANK">KINA BANK</MenuItem>
                                                        <MenuItem value="WESTPACC">WESTPAC</MenuItem>
                                                        <MenuItem value="ANZ">ANZ</MenuItem>
                                                    </Select>
                                                )}></Controller>
                                    <FormHelperText>{errors?.bank?.message?.toString()}</FormHelperText>
                                </FormControl>

                                <Controller name="branch_Number" render={({field})=> <TextField error={errors.branch_Number && true}  helperText={errors?.branch_Number?.message?.toString()}
                                            sx={{background:"white"}}
                                            label='Bank Branch Code' {...field}/> }
                                            control = {control}
                                            rules={{required:{value:true,message:"Requiered"}}}
                                            defaultValue=""/>

                                <Controller name="branch_Name" render={({field})=> <TextField error={errors.branch_Name && true}  helperText={errors?.branch_Name?.message?.toString()}
                                            sx={{background:"white"}}
                                            label='Bank Branch Name' {...field}/> }
                                            control = {control}
                                            rules={{required:{value:true,message:"Requiered"}}}
                                            defaultValue=""/>

                                <Controller name="account_Number" render={({field})=> <TextField error={errors.account_Number && true}  helperText={errors?.account_Number?.message?.toString()}
                                            sx={{background:"white"}}
                                            label='Account Number' {...field}/> }
                                            control = {control}
                                            rules={{required:{value:true,message:"Requiered"}}}
                                            defaultValue=""/>

                                <Controller name="account_Name" render={({field})=> <TextField error={errors.account_Name && true}  helperText={errors?.account_Name?.message?.toString()}
                                            sx={{background:"white"}}
                                            label='Account Name' {...field}/> }
                                            control = {control}
                                            rules={{required:{value:true,message:"Requiered"}}}
                                            defaultValue=""/>

                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{background:"#F7FAFD"}} expanded={store.registrationStore.expandPanel4} onChange={handleChange("panel4")}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel2-content"
                                          id="panel2-header">
                            <Typography> Document Attachments</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack spacing={2} >
                                <Grid container spacing={1} columns={{xs: 4, sm: 8, md: 12}} alignItems="center">
                                    <Grid item  xs={4} sm={6} md={9}>
                                        <Controller   name="tin_Certificate"  control={control} render={({field,fieldState})=>(
                                            <MuiFileInput fullWidth={true} placeholder="Click to Select a File" sx={{background:"white"}} label="File Upload - TIN Certificate"  {...field} helperText={fieldState.invalid ? "File is invalid" : ""}   error={fieldState.invalid}

                                            />)}/>
                                    </Grid>
                                    <Grid item xs={4} sm={6} md={3}>
                                        <Button
                                            disabled={!(getValues("tin_Certificate")?.name ?? getValues("tin_Certificate"))}
                                            sx={{textTransform:'none'}}
                                            fullWidth={true}
                                            component="a"
                                            href={store?.registrationStore?.tinDownloadUrl ?? "#"}
                                            role={undefined}
                                            variant="contained"
                                            startIcon={<CloudDownloadIcon />}
                                        >
                                            <Stack>
                                                <Typography>Download</Typography>
                                                <Typography  sx={{fontSize:"12px"}}>  {getValues("tin_Certificate")?.name ?? getValues("tin_Certificate")}</Typography>
                                            </Stack>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                    <Grid container spacing={{xs: 2, md: 3}} columns={{xs: 4, sm: 8, md: 12}} justifyContent="center">
                        <Grid item>
                            <Stack direction="column"
                                   spacing={2}
                                   justifyContent="space-evenly"
                                   alignItems="center">
                                <FormControl fullWidth={true}>
                                    <InputLabel id="sign_label">Signature Type</InputLabel>
                                    <Select id="sig" label="sign_label" labelId="sign_label" onChange={change}
                                            defaultValue="Signature">
                                        <MenuItem value="Signature">Sign on Screen</MenuItem>
                                        <MenuItem value="UploadFile">Upload File</MenuItem>
                                    </Select>
                                </FormControl>
                                <Box component="div" hidden={!store.registrationStore.uploadingSignatureFile}>
                                    <Controller name="medical_Practitioner_Signiture" control={control}
                                                render={({field}) => (
                                                    <ImageUpload onChange={(data) => field.onChange(data)}
                                                                 value={field.value}/>)}/>
                                </Box>
                                <Box sx={{border:1}} component="div" hidden={store.registrationStore.uploadingSignatureFile}>
                                    <Controller name="medical_Practitioner_Signiture" control={control}
                                                render={({field}) => (
                                                    <Signature onChange={(data) => field.onChange(data)}
                                                               value={field.value}/>)}/>
                                </Box>
                            </Stack>

                        </Grid>
                    </Grid>
                    <Grid container spacing={{xs: 2, md: 3}} columns={{xs: 4, sm: 8, md: 12}} justifyContent="center">
                        <Grid item xs={4} sm={8} md={12} alignItems="stretch">
                            <LoadingButton
                                sx={{width: "100%", height: "100%"}}

                                loading={mloading}
                                loadingIndicator=" Saving…"
                                variant="contained"
                                type="submit"
                            >
                                <span>Save</span>
                            </LoadingButton>

                        </Grid>
                    </Grid>
                </Stack>
                <Snackbar
                    anchorOrigin={{vertical:"top",horizontal: "center"}}
                    open={store.appStore.openSnackBar}
                    autoHideDuration={1000}
                    onClose = {handleClose}
                    message ={store.appStore.snackBarMessage}
                />
            </Grid>
        </Grid>
    );
});