import { makeAutoObservable} from "mobx";


export class Index{

    constructor() {
        makeAutoObservable(this);
    }
    signedout: boolean = false;
    userId :string|null = null;
    registrationId: string|null = null;
    reason :string|null = null ;
    authStatus :string | null="configuring";
    setSignedout(val:boolean){
        this.signedout = val;
    }
    setUserId(userId:string){
        this.userId = userId;
    }
    setReason(reason:string){
        this.reason = reason;
    }
    setAuthStatus(authStatus:string | null){
            this.authStatus = authStatus;
    }

    setRegistrationId(regId:string){
        this.registrationId = regId;
    }



}